import React, { useState } from 'react'
import moment from "moment"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import axios from "../../helpers/axios"
import { useSnackbar } from "notistack"
import useToken from '../../helpers/custom-hooks/useToken'
import endpoints from "../../helpers/endpoints"
import SolvedDoubts from "./SolvedDoubts"

export default function DoubtCount() {

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [result, setResult] = useState("");
    const [loading, setLoading] = useState(true);
    const { token } = useToken()
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": token,
        }
    }

    async function handleClickfilter() {
        let url = endpoints.getDoubtSolverCount
        if(startDate && endDate){
           url += `fromDate=${moment(startDate).startOf('day').utc()}&toDate=${moment(endDate).endOf('day').utc()}`
        }
        axios
            .post(url, {data : ""}, config)
            .then((response) => {
                if(response.status === 200){
                    setResult(response.data.solvedResult)
                    setLoading(false)
                }
            })
            .catch((error) => {
                enqueueSnackbar("Doubt assignement failed: " + error.response.data, { variant: 'error' })
            })
    }

    const filterResult = (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: '#2e7d32' }}>
                        <CheckCircleIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Solved" secondary={`${result}`} />
            </ListItem>
        </List>
    )

    const noOfDoubtsCard = (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item sx={{ p: 1 }} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => {
                            setStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item sx={{ p: 1 }} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => {
                            setEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item sx={{ p: 1 }} md={12} xs={12}>
                <Button onClick={handleClickfilter} sx={{ width: '100%', background: '#000', '&:hover': {background: 'black'} }} variant="contained" color="success">
                    Search
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <React.Fragment>
            <Stack
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{m: 1}}
                spacing={3}
            >
                <Paper sx={{ p: { md: 4, xs: 2 },maxWidth: 700 }} elevation={3}>
                    {noOfDoubtsCard}
                </Paper>
                 {!loading && <Paper sx={{ p: { md: 4, xs: 2 }, mt: 3, maxWidth: 700, }} elevation={3}>
                    {filterResult}
                </Paper>}
                <SolvedDoubts/>
            </Stack>
        </React.Fragment>
    )
}
