import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'

import axios from "../helpers/axios"
import endpoints from "../helpers/endpoints"
import { useSnackbar } from "notistack"
import useToken from '../helpers/custom-hooks/useToken'
import BasicSelector from './BasicSelector'

export default function SubjectSelector(props) {
    const [courseLoading, setCourseLoading] = useState(false)
    const [subjectLoading, setSubjectLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const [subjects, setSubjects] = useState([])
    
    const { token } = useToken()
    const { enqueueSnackbar } = useSnackbar()
    const config = { headers: { "x-auth-token": token } }

    useEffect(() => {
        setCourseLoading(true)
        axios
            .get(endpoints.getDoubtExplorerAssignedSubjects, config)
            .then((response) => {
                setCourses(response.data)
                setCourseLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt course data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }, [])

    useEffect(() => {
        if (!props.courseId) return
        setSubjectLoading(true)
        axios
            .get(endpoints.getSubjectsOfCourse + `?courseId=${props.courseId}`, config)
            .then((response) => {
                setSubjects(response.data)
                setSubjectLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt subject data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }, [props.courseId])

    return (
        <Stack alignItems={{ xs: 'center', md: 'flex-start' }} spacing={3} direction={{ xs: 'column', md: 'row' }}>
            <BasicSelector
                loading={courseLoading}
                title={'Course'}
                selected={props.courseId}
                data={courses}
                handleChange={(courseId) => {
                    props.handleChangeCourse(courseId)
                    props.handleChangeSubject('')
                }}
            />
            <BasicSelector
                loading={subjectLoading}
                title={'Subject'}
                selected={props.subjectId}
                data={subjects}
                handleChange={(subjectId) => props.handleChangeSubject(subjectId)}
            />
        </Stack>
    )
}
