import { useState } from 'react';
import endpoints from '../endpoints';
import axios from "../axios"

export default function useToken() {

    const getToken = () => {
        const tokenString = localStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken?.token
    }

    const [token, setToken] = useState(getToken())

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken))
        setToken(userToken.token)
    }

    const destroyToken = async() => {
        localStorage.setItem('token', null)
        setToken(null)
        window.location.href= "/"
        await handleUnassignDoubt()
    }

    const handleUnassignDoubt = async ()=>{
        const config = {
            headers: {
                "x-auth-token": token,
            }
        }
        await axios.get(endpoints.doubtExplorerAutoUnassign, config)
        return
    }

    return {
        token,
        setToken: saveToken,
        destroyToken: destroyToken
    }
}