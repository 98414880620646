import { BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css'
import DoubtExplorer from './components/DoubtExplorer/DoubtExplorer'
import BasicLayout from './components/Layouts/BasicLayout'
import Login from './pages/Login'
import useToken from './helpers/custom-hooks/useToken'
import DashBoard from "./pages/DashBoard/DashBoard"
import DoubtCount from "./pages/DoubtCount/DoubtCount"

function App() {
    const {setToken, destroyToken} = useToken()
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login setToken={setToken} />} />
                    <Route element={<BasicLayout logoutAction={destroyToken} />}>
                        <Route path="/dashboard" element={<DashBoard/>} />
                    </Route>
                    <Route element={<BasicLayout logoutAction={destroyToken} />}>
                        <Route path="/solved-doubt" element={<DoubtCount/>} />
                    </Route>
                    <Route element={<BasicLayout logoutAction={destroyToken} />}>
                        <Route path="/doubt-explorer" element={<DoubtExplorer/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
