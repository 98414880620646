import React, { useState } from 'react'
import { useNavigate } from 'react-router'
/* @MUI COMPONENT */
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

/* @CUSTOM COMPONENT */
import axios from '../helpers/axios'
import logo from '../assets/images/logo.png'
import endpoints from '../helpers/endpoints'
import { useSnackbar } from "notistack"

export default function Login({ setToken }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    async function loginUser(credentials) {
        return axios
            .post(endpoints.login, credentials)
            .then((response) => {
                return response.headers["x-auth-token"]
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data, { variant: 'error' })
            })
    }

    const handleSubmit = async e => {
        e.preventDefault()

        if(email.length === 1) return enqueueSnackbar("Invalid email.", { variant: 'error' })
        if(password.length === 1) return enqueueSnackbar("Password is not allowed to be empty.", { variant: 'error' })

        const token = await loginUser({
            email: email,
            password: password
        })
        if (!token || token === '') {
            enqueueSnackbar("Login failed. Please try again.", { variant: 'error' })
            return
        }
        setToken({ token: token })
        enqueueSnackbar("Login successful", { variant: 'success' })
        navigate('/doubt-explorer', {replace: true})
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, p: 2, width: '70px', height: '70px', backgroundColor : 'white'}}>
                    <img src={logo} style={{ width: '50px', height: '50px'}} alt="logo" />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}
