import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Rating from '@mui/material/Rating';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/More';
import Tooltip from '@mui/material/Tooltip';

import axios from "../../helpers/axios"
import { useSnackbar } from "notistack"
import useToken from '../../helpers/custom-hooks/useToken'
import endpoints from "../../helpers/endpoints"
import SimpleBackdrop from "../../components/SimpleBackdrop"
import DoubtSolveDialog from './DoubtSolveDialog';

const TAG = "DoubtExplorer"
const generateKey = (pre) => {
    return `${TAG}_${pre}_${new Date().getTime()}`
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function SolvedDoubts() {
    const [doubtId, setDoubtId] = useState('')
    const [loading, setLoading] = useState(false)
    const [doubts, setDoubts] = useState([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const { token } = useToken()
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": token,
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    async function fetchSolvedDoubts() {
        try {
            setLoading(true)
            const response = await axios.get(endpoints.getSolvedDoubtsOfSolver + `?row=${rowsPerPage}&pageNo=${page}`, config)
            setDoubts(response.data.result)
            setCount(response.data.count)
            setLoading(false)
        } catch (error) {
            enqueueSnackbar(error.response.data, { variant: "error" })
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchSolvedDoubts()
    }, [page, rowsPerPage])

    return (
        <TableContainer component={Paper}>
            {loading && <SimpleBackdrop />}
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">Serial</StyledTableCell>
                        <StyledTableCell align="left">Student Name</StyledTableCell>
                        <StyledTableCell align="left">Course</StyledTableCell>
                        <StyledTableCell align="left">Subject</StyledTableCell>
                        <StyledTableCell align="left">Review</StyledTableCell>
                        <StyledTableCell align="left">Comment</StyledTableCell>
                        <StyledTableCell align="left">Details</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {doubts?.map((item, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell align="left">{(page * rowsPerPage + 1) + index}</StyledTableCell>
                            <StyledTableCell align="left">{item?.user?.name}</StyledTableCell>
                            <StyledTableCell align="left">{item?.course?.title}</StyledTableCell>
                            <StyledTableCell align="left">{item?.subject?.title}</StyledTableCell>
                            <StyledTableCell align="left">
                                <Rating name="read-only" value={item?.review?.rating} readOnly />
                            </StyledTableCell>
                            <StyledTableCell align="left">{item?.comments[item?.comments.length - 1]?.comment}</StyledTableCell>
                            <StyledTableCell align="left">
                                <Tooltip title="Details" placement="bottom">
                                    <IconButton onClick={() => setDoubtId(item?._id)}>
                                        <MoreIcon />
                                    </IconButton>
                                </Tooltip>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DoubtSolveDialog
                key={generateKey("doubt-dialog")}
                doubtId={doubtId}
                open={doubtId ? true : false}
                handleCloseDialouge={() => setDoubtId('')}
            />
        </TableContainer>
    );
}
