import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import Wrapper from './Wrapper'
import useToken from '../../helpers/custom-hooks/useToken'

export default function BasicLayout({ logoutAction }) {

    const navigate =  useNavigate()
    const {token} = useToken()

    useEffect(()=>{
        if(!token){
            navigate("/");
        }
    }, [token, navigate])
    
    return (
        <Wrapper logoutAction={logoutAction}>
            <Box sx={{ p: 2 }}>
                <Outlet />
            </Box>
        </Wrapper>
    )
}
