const endpoints = {
    login : '/doubtAdmin/login',

    getDoubtSolverCount: '/doubtAdmin/doubts/count?',
    getDoubts: '/doubtAdmin/doubts?',
    filterDoubts: '/doubtAdmin/doubts/filter-doubts',
    getDoubtById: '/doubtAdmin/doubts/',
    updateDoubtById: '/doubtAdmin/doubts/',
    uploadDoubtAnswerImage: '/doubtAdmin/upload/doubtAnswerImage',

    doubtExplorerMyDoubts: '/doubtAdmin/doubt-explorer/myDoubts?',
    doubtExplorerAssignToMe: '/doubtAdmin/doubt-explorer/assignToMe',
    doubtExplorerUnassign: '/doubtAdmin/doubt-explorer/unassign',
    doubtExplorerAutoUnassign: '/doubtAdmin/doubt-explorer/auto-unassign',
    getDoubtExplorerAssignedSubjects: '/doubtAdmin/doubt-explorer/my-courses',
    getSubjectsOfCourse: '/doubtAdmin/doubt-explorer/subjects',

    getSolvedDoubtsOfSolver: '/doubtAdmin/doubt-explorer/solved-doubts',
    getDoubtById: '/doubtAdmin/doubts/details/',
}

export default endpoints