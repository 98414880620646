import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import Stack from '@mui/material/Stack'
import { Tooltip } from '@mui/material'

import SimpleBackdrop from '../SimpleBackdrop'
import axios from "../../helpers/axios"
import endpoints from "../../helpers/endpoints"
import { useSnackbar } from "notistack"
import DoubtSolveDialog from './DoubtSolveDialog'
import useToken from '../../helpers/custom-hooks/useToken'
import SubjectSelector from '../SubjectSelector'
import IsolatedMenu from "../IsolatedMenu";

const TAG = "DoubtExplorer"
const generateKey = (pre) => {
    return `${TAG}_${pre}_${new Date().getTime()}`
}

export default function DoubtExplorer() {
    const [loading, setLoading] = useState(false)
    const [selectedDoubt, setSelectedDoubt] = useState('')
    const [doubtAssignLoading, setdoubtAssignLoading] = useState(false)
    const [doubts, setDoubts] = useState([])
    const [assignedDoubts, setAssignedDoubts] = useState([])
    const [openDoubtDialog, setOpenDoubtDialog] = useState(false)
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [selectedSubjectId, setSelectedSubjectId] = useState('');

    const [refreshKey, setRefreshKey] = useState(0)
    const { token } = useToken()
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": token,
        }
    }

    const handleOpenAction = (doubtId) => {
        setSelectedDoubt(doubtId)
        setOpenDoubtDialog(true)
    }

    const handleAssignAction = (doubtId) => {
        setdoubtAssignLoading(true)
        if (assignedDoubts.length) {
            enqueueSnackbar("Please solve currently assigned doubts")
            setdoubtAssignLoading(false)
            return
        }
        assignDoubtToMe(doubtId)
    }

    const handleUnassignAction = (doubtId) => {
        unAssignDoubt(doubtId)
    }

    const refresh = () => {
        setRefreshKey(prevKey => prevKey + 1)
    }

    async function assignDoubtToMe(doubtId) {
        axios
            .post(endpoints.doubtExplorerAssignToMe, { doubtId: doubtId }, config)
            .then((response) => {
                enqueueSnackbar(response.data)
                setdoubtAssignLoading(false)
                refresh()
            })
            .catch((error) => {
                setdoubtAssignLoading(false)
                enqueueSnackbar("Doubt assignement failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function unAssignDoubt(doubtId) {
        axios
            .post(endpoints.doubtExplorerUnassign, { doubtId: doubtId }, config)
            .then((response) => {
                enqueueSnackbar(response.data)
                refresh()
            })
            .catch((error) => {
                enqueueSnackbar("Doubt unassigning failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        if (selectedSubjectId) return
        axios
            .get(endpoints.getDoubts + `status=Unassigned`, config)
            .then((response) => {
                setDoubts(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }, [refreshKey])

    useEffect(() => {
        if (!selectedSubjectId) return
        setLoading(true)
        axios
            .get(endpoints.filterDoubts + `?subjectId=${selectedSubjectId}&courseId=${selectedCourseId}`, config)
            .then((response) => {
                setDoubts(response.data)
                setLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }, [refreshKey, selectedSubjectId])

    useEffect(() => {
        axios
            .get(endpoints.doubtExplorerMyDoubts + "status=Assigned", config)
            .then((response) => {
                setAssignedDoubts(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response.data, { variant: 'error' })
            })

    }, [refreshKey])

    const assignedDoubtsCard = (
        <Card>
            <CardHeader title={
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="primary"
                    variant="body1"
                    component="div"
                >
                    Assigned To Me
                </Typography>
            } />
            <CardContent>
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width="10%">Serial</TableCell>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="left">Course</TableCell>
                                <TableCell align="left">Subject</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignedDoubts.map((doubt, index) => (
                                <TableRow
                                    key={doubt._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{doubt.comments[0] ? doubt.comments[0].comment : ""}</TableCell>
                                    <TableCell align="left">{doubt?.course?.title}</TableCell>
                                    <TableCell align="left">{doubt?.subject?.title}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Open">
                                            <IconButton onClick={() => handleOpenAction(doubt._id)}>
                                                <OpenInBrowserIcon color="success" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Unassign" onClick={() => handleUnassignAction(doubt._id)}>
                                            <IconButton>
                                                <PersonRemoveIcon color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )

    const openDoubtsCard = (
        <Card>
            {loading && <SimpleBackdrop />}
            <CardHeader title={
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="primary"
                    variant="body1"
                    component="div"
                >
                    Open Doubts
                </Typography>
            } />
            <SubjectSelector
                subjectId={selectedSubjectId}
                courseId={selectedCourseId}
                handleChangeSubject={(subjectId) => setSelectedSubjectId(subjectId)}
                handleChangeCourse={(courseId) => setSelectedCourseId(courseId)}
            />
            <CardContent>
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width="10%">Serial</TableCell>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="left">Course</TableCell>
                                <TableCell align="left">Subject</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {doubts.map((doubt, index) => (
                                <TableRow
                                    key={doubt._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{doubt.comments[0] ? doubt.comments[0].comment : ""}</TableCell>
                                    <TableCell align="left">{doubt?.course?.title}</TableCell>
                                    <TableCell align="left">{doubt?.subject?.title}</TableCell>
                                    <TableCell>
                                        <IsolatedMenu
                                            doubtId={doubt?._id}
                                            handleSuccess={(doubtId) => handleAssignAction(doubtId)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )

    return (
        <React.Fragment>
            {doubtAssignLoading && <SimpleBackdrop />}
            <Stack direction={"column"} spacing={2}>
                {assignedDoubts.length ? assignedDoubtsCard : null}
                {openDoubtsCard}
            </Stack>
            {selectedDoubt && <DoubtSolveDialog
                key={generateKey("doubt-dialog")}
                doubtId={selectedDoubt}
                open={openDoubtDialog}
                handleClose={() => setOpenDoubtDialog(false)}
                handleSuccess={refresh}
            />}
        </React.Fragment>
    )
}
