import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function DoubtReview({ open, answerFile, comment, handleCloseDialog, handleSubmit }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        handleCloseDialog(false)
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle sx={{ color: '#0066CB', fontWeight: 700 }} id="responsive-dialog-title">
                    {"Doubt Review"}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={3} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ p: { xs: 2, md: 4 }, minWidth: 500 }}>
                        <Card>
                            <CardHeader title="Answer Image" />
                            {answerFile && <CardMedia
                                component="img"
                                image={URL.createObjectURL(answerFile)}
                            />}
                        </Card>
                        <Card sx={{minWidth:400}}>
                            <CardHeader title="Comment" />
                            <Divider />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar>DS</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ mt: 2 }}
                                    primary={comment}
                                />
                            </ListItem>
                            <Divider />
                        </Card>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} variant="contained" autoFocus onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button sx={{ textTransform: 'none' }} variant="outlined" onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
