import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Typography from '@mui/material/Typography';
import moment from 'moment';
export default function DoubtTimeLine({ timeline }) {
    return (
        <Timeline position="alternate">
            <Typography variant="h4" fontWeight={700} sx={{ opacity: 0.7, textAlign: 'center' }}>Timeline</Typography>
            {timeline?.map((item, index) =>
                <TimelineItem key={index}>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align={index % 2 === 0 ? "right" : "left"}
                        variant="body2"
                        color="text.secondary"
                    >
                        {moment(item?.timeStamp).format('DD-MM-YYYY HH:mm A')}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        {item?.event === 'Created' &&
                            <TimelineDot color="primary">
                                <AddBoxIcon />
                            </TimelineDot>
                        }
                        {item?.event === 'Assigned' &&
                            <TimelineDot sx={{ bgcolor: 'secondary.main' }}>
                                <AssignmentTurnedInIcon />
                            </TimelineDot>
                        }
                        {item?.event === 'Replied' &&
                            <TimelineDot color="primary">
                                <QuickreplyIcon />
                            </TimelineDot>
                        }
                        {item?.event === 'Solved' &&
                            <TimelineDot color="success">
                                <CheckCircleIcon />
                            </TimelineDot>
                        }
                        {item?.event === 'Flagged' &&
                            <TimelineDot>
                                <AssignmentTurnedInIcon />
                            </TimelineDot>
                        }
                        {item?.event === 'Reviewed' &&
                            <TimelineDot color="primary">
                                <RateReviewIcon />
                            </TimelineDot>
                        }
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '20px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            {item?.event}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            )}
        </Timeline>
    );
}
