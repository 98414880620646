import * as React from 'react';
import { useLocation } from "react-router-dom"
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import { useNavigate } from 'react-router-dom';

export const SidebarItems = () => {

  const navigate = useNavigate()
  const currentLocation = useLocation()

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate('/doubt-explorer')} selected={currentLocation.pathname === '/doubt-explorer' ? true : false}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Doubt Explorer" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/solved-doubt')} selected={currentLocation.pathname === '/solved-doubt' ? true : false}>
        <ListItemIcon>
          <DoNotDisturbOnTotalSilenceIcon />
        </ListItemIcon>
        <ListItemText primary="Solved Doubt" />
      </ListItemButton>
    </React.Fragment>
  )
}