import React, { useEffect, useState } from "react"
import { useSnackbar } from "notistack"

/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'
import CardContent from "@mui/material/CardContent"
import CardActions from '@mui/material/CardActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';

/* @CUSTOM COMPONENT */
import axios from "../../helpers/axios"
import useToken from '../../helpers/custom-hooks/useToken'
import endpoints from "../../helpers/endpoints"
import SimpleBackdrop from "../../components/SimpleBackdrop"
import DoubtTimeLine from "./DoubtTimeLine"
import Grid from "@mui/material/Grid"

export default function DoubtSolveDialog(props) {
    const [doubt, setDoubt] = useState('')
    const [doubtImage, setDoubtImage] = useState(new Blob())
    const [doubtAnswerImage, setDoubtAnswerImage] = useState(new Blob())
    // const [comment, setComment] = useState('')
    const [doubtLoading, setDoubtLoading] = useState(false)
    // const [submitLoading, setSubmitLoading] = useState(false)
    const { token } = useToken()
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": token,
        }
    }

    const handleClose = () => {
        props.handleCloseDialouge()
    }

    async function fetchDoubt(doubtId) {
        setDoubtLoading(true)
        axios
            .get(endpoints.getDoubtById + doubtId, config)
            .then((response) => {
                setDoubt(response.data)
                fetchDoubtImage(response.data.questionImage)
                if (response.data?.answerImage) {
                    fetchDoubtAnswerImage(response.data.answerImage)
                }
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response.data, { variant: 'error' })
                setDoubtLoading(false)
            })
    }

    async function fetchDoubtImage(imageLink) {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bucket/` + imageLink, { responseType: 'blob' })
            .then((response) => {
                setDoubtLoading(false)
                if (response.data.statusCode) {
                    enqueueSnackbar(response.data.message, { variant: 'error' })
                    return
                }
                setDoubtImage(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response, { variant: 'error' })
            })
    }

    async function fetchDoubtAnswerImage(imageLink) {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bucket/` + imageLink, { responseType: 'blob' })
            .then((response) => {
                setDoubtLoading(false)
                if (response.data.statusCode) {
                    enqueueSnackbar(response.data.message, { variant: 'error' })
                    return
                }
                setDoubtAnswerImage(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response, { variant: 'error' })
            })
    }

    // async function updateDoubt(image) {
    //     if (image === '' && comment === '') {
    //         enqueueSnackbar("Image or comment required", { variant: 'error' })
    //         return
    //     }
    //     var updatePayload = {}
    //     if (image !== '') {
    //         updatePayload = { ...updatePayload, answerImage: image }
    //     }

    //     if (comment !== '') {
    //         updatePayload = { ...updatePayload, comment: comment }
    //     }

    //     setSubmitLoading(true)

    //     axios
    //         .put(endpoints.updateDoubtById + props.doubtId, updatePayload, config)
    //         .then((response) => {
    //             setSubmitLoading(false)
    //             enqueueSnackbar(response.data, { variant: 'success' })
    //             handleClose()
    //             props.handleSuccess()
    //         })
    //         .catch((error) => {
    //             enqueueSnackbar("Doubt solve failed: " + error.response.data, { variant: 'error' })
    //         })
    // }

    useEffect(() => {
        if (!props.open) return
        fetchDoubt(props.doubtId)
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Doubt Solve
                </Typography>
                {/* <LoadingButton
                    color="secondary"
                    disabled
                    // onClick={handleSubmit}
                    loading={doubtLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton> */}
            </Toolbar>
        </AppBar>
    )

    const doubtImageCard = (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader title="Question Image" />
            <CardMedia
                component="img"
                image={URL.createObjectURL(doubtImage)}
            />
        </Card>
    )

    const commentsCard = (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader title="Comments" />
            <CardContent>
                <List>
                    {doubt ?
                        doubt?.comments?.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Divider />
                                    <ListItem sx={{ m: 2 }} alignItems="flex-start">
                                        <ListItemAvatar sx={{ width: '25px', height: '25px', mt: -1 }}>
                                            {item?.user?.role === "doubtSolver" ?
                                                <Avatar>DS</Avatar> :
                                                <Avatar>ST</Avatar>}
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item?.comment}
                                        />
                                    </ListItem>
                                </div>
                            )
                        }) : null
                    }
                </List>
            </CardContent>
        </Card>
    )

    const reviewCard = (
        <Card sx={{ minWidth: 400 }}>
            <CardHeader title="Review" />
            <CardContent>
                <List>
                    {doubt ?
                        <div>
                            <Divider />
                            <ListItem sx={{ m: 2 }} alignItems="flex-start">
                                <ListItemAvatar sx={{ width: '25px', height: '25px', mt: -1 }}>
                                    <Avatar>ST</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={doubt?.review?.comment}
                                    secondary={
                                        <Rating name="read-only" value={doubt?.review?.rating} readOnly />
                                    }
                                />
                            </ListItem>
                        </div>
                        : null
                    }
                </List>
            </CardContent>
        </Card>
    )

    const answeredImage = (
        <Card sx={{ minWidth: 400 }}>
            {
                doubtAnswerImage ?
                    <CardMedia
                        component="img"
                        image={URL.createObjectURL(doubtAnswerImage)}
                    /> : <Skeleton variant="rectangular" width={400} height={236} />
            }
            <CardActions disableSpacing>
                <label htmlFor="doubt-answer">
                    <Typography variant="h6" gutterBottom align="center">Answer Image</Typography>
                </label>
            </CardActions>
        </Card>
    )

    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {doubtLoading ? <SimpleBackdrop /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={8}>
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={12} md={6}>
                                <Stack direction="column" spacing={4}>
                                    {doubtImageCard}
                                    {commentsCard}
                                    {doubt?.answerImage && answeredImage}
                                    {reviewCard}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DoubtTimeLine timeline={doubt?.timeline} />
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>}
        </Dialog>
    )
}