import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
export default function BasicSelector({ selected, loading, title, data, handleChange }) {
    return (
        <div>
            {loading ?
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <Skeleton variant="rectangular" width={200} height={30} />
                </FormControl> : <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-standard-label">{title}</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selected}
                        onChange={(event)=>handleChange(event.target.value)}
                        label={title}
                    >{data?.map((item, index) =>
                        <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    )}
                    </Select>
                </FormControl>}
        </div>
    );
}
